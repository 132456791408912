<template>
  <div class="main-box" v-cloak>
    <!-- 背景图 -->
    <div class="logo">
      <img
        src="../assets/img/banner_cases.png"
        alt=""
        style="width: 100%; height: auto"
      />
      <div class="container h-100 position">
        <div class="logo-box">
          <h1>零售行业SCRM & 营销案例</h1>
          <h3>
            SCRM系统与自动化营销系统让零售品牌更深入洞悉客户个体化需求，在降低营销成本的同时，创造更高的消费转化率
          </h3>
        </div>
      </div>
    </div>
    <!-- 导航条 -->
    <div class="container position">
      <tabbar />
    </div>
    <!-- 内容主体 -->
    <div class="container">
      <div class="content">
        <h3 class="mb-40">行业背景介绍</h3>
        <p class="mb-40">
          某知名大型商超集团是传统零售业务的领军企业，其核心业务覆盖百货、超市、商
          业综合体等，会员级客户超过200万人，但用户整体月度复购率指标不理想。
          集团在近年一直注重私域客户运营的基础平台建设投入，并在2020年布局私域流量运营，为客户提供“有温度的客户服务”。
        </p>
        <h3 class="mb-40">行业痛点</h3>
        <p>1、什么样的服务才能满足消费者，优质的服务体验，优惠的商品？</p>
        <p class="mt-25">
          2、如何深入了解目标消费者，精准触达潜在客户，有效将客流引入门店消费？
        </p>
        <p class="mt-25">
          3、如何利用多渠道触达消费者。进行大数据的精准营销与全渠道引流？
        </p>
        <p class="mt-25">
          4、如何对入店客户进行精准分类分析，定位目标客户群，明确需求，从而进行营销策略调整。
        </p>
        <div class="content-img">
          <!-- <img src="../assets/img/1-11.png" alt=""> -->
        </div>
        <h3 class="mt-60">解决方案</h3>
        <h4 class="mb-40">
          企业微信+赢客通平台+电商运营+多系统数据互通+运营支撑
        </h4>
        <p>统一运营，整合门店导购资源力量配合线上用户沉淀。</p>
        <p>
          全面接入赢客通建立新零售营销管理体系，借助平台服务能力提升线上线下运
          营效率。以门店服务半径为纬度建立门店级的私域用户池。打通汇总至品牌私域池。
        </p>
        <h3 class="mt-25 mb-40">取得成效</h3>
        <p>
          初步建立私域流量运营的平台，完成对私域用户的画像。并按照画像进行差异
          化信息推送。
        </p>
        <p>3个月内私域池沉淀活跃用户20多万人。私域用户单月复购率达34.5%。</p>
      </div>
    </div>
    <!-- 客户 -->
    <div class="customer">
      <div class="container">
        <h2>客户</h2>
        <div>
          <img src="../assets/img/组 519.png" alt="" />
          <img src="../assets/img/组 520.png" alt="" />
        </div>
      </div>
    </div>
    <tail />
  </div>
</template>

<script>
import Tabbar from "../components/common/tabbar.vue";
import Tail from "../components/common/tail.vue";
export default {
  components: {
    Tabbar,
    Tail,
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  position: relative;
  width: 100%;
  height: 100%;
  .logo {
    width: 100%;
    overflow: hidden;
    position: relative;
    .logo-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      // border: 1px solid #fff;
      width: 820px;
      h1 {
        width: 785px;
        height: 84px;
        font-size: 60px;
        font-weight: 700;
        text-align: left;
        line-height: 60px;
        letter-spacing: 2px;
      }
      h3 {
        width: 818px;
        height: 90px;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        line-height: 34px;
        letter-spacing: 2px;
      }
    }
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    margin-top: 80px;
    // border: 1px solid olivedrab;
    h3 {
      width: 100%;
      height: 31px;
      line-height: 30px;
      font-size: 24px;
      font-weight: 700;
      color: #5e91d4;
    }
    p {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      // letter-spacing: 1px;
      // border: 1px solid khaki;
    }
    .content-img {
      width: 100%;
      height: 446px;
      overflow: hidden;
      margin-top: 60px;
      background-image: url("../assets/img/zhang-kaiyv-bMQQ6faWlMo-unsplash.png");
      background-repeat: no-repeat;
      background-size: 100% 446px;
    }
    h4 {
      color: #006eff;
      font-size: 18px;
      margin-top: 20px;
    }
  }
  .customer {
    width: 100%;
    overflow: hidden;
    height: 292px;
    background-color: #f5f6f8;
    margin-top: 93px;
    margin-bottom: 75px;
    h2 {
      width: 100%;
      height: 47px;
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      color: #333333;
      letter-spacing: 2px;
      margin-top: 39px;
      margin-bottom: 47px;
    }
    div {
      width: 100%;
      height: 111px;
      // display: flex;
      // justify-content: space-around;
      // padding: 0 100px;
      // box-sizing: border-box;
      text-align: center;
      img {
        width: 249px;
        height: 111px;
        overflow: hidden;
      }
      img:last-child {
        margin-left: 41px;
      }
    }
  }
}
.position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.h-100 {
  height: 100%;

  // border: 1px solid snow;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-60 {
  margin-top: 60px;
}[v-cloak] {
  display: none;
}
</style>