<template>
  <!-- 尾部内容 -->
  <div class="tail">
    <div class="container">
      <div class="top">
        <div class="top-left">
          <p>
            ©2021 快客云科技（武汉）有限公司
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >鄂ICP备18013513号</a
            >
          </p>
          <p>湖北省武汉市江汉区常青路武汉天街写字楼5栋2008</p>
        </div>
        <ul class="top-right">
          <li>
            <ul>
              <li class="f-14">联系我们</li>
              <li class="f-14">商务合作：027-83351051</li>
            </ul>
          </li>
          <li>
            <ul>
              <li class="f-14">服务条款</li>
              <li>隐私政策</li>
              <li>用户协议</li>
              <li>关于我们</li>
              <li>简介</li>
            </ul>
          </li>
          <li>
            <ul>
              <li class="f-14">企业公众号</li>
              <li class="li-img"></li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- <ul class="bottom">
        <li>友情链接</li>
        <li>企业数字化转型</li>
        <li>直播app源码</li>
        <li>企业网盘</li>
        <li>云队友远程工作</li>
        <li>陪玩APP源码</li>
        <li>图普科技</li>
        <li>BI软件搜题找答案</li>
        <li>SaaS企业</li>
        <li>私域流量关键词</li>
        <li>私域流量资讯</li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.tail {
  width: 100%;
  // height: 242px;
  height: calc(242px - 66px);
  background-color: #2f313e;
  font-size: 12px;
  color: #ccc;
  overflow: hidden;
  .top {
    height: 175px;
    width: 100%;
    overflow: hidden;
    // background-color: magenta;
    display: flex;
    justify-content: space-between;
    .top-left {
      // border: 1px solid springgreen;
      width: 430px;
      height: 100%;
      padding-top: 54px;
      P {
        height: 30px;
        line-height: 20px;
      }
    }
    .top-right {
      // border: 1px solid blue;
      width: 600px;
      height: 100%;
      display: flex;
      justify-content: space-around;
      li {
        padding: 14px 0 10px 0;
        .li-img {
          display: inline-block;
          width: 100px;
          height: 100px;
          // overflow: hidden;
          background-image: url("../../assets/img/erweima.jpg");
          background-repeat: no-repeat;
          background-size: 100px 100px;
        }
        li {
          padding: 5px 0;
        }
      }
    }
  }
  .bottom {
    height: 66px;
    // background-color: maroon;
    border-top: 1px solid #fff;
    display: flex;
    justify-content: space-around;
    line-height: 66px;
  }
}
.f-14 {
  font-size: 14px;
}
a {
  text-decoration: none;
  color: #fff;
}
a:hover {
  color: #409eff;
}
</style>