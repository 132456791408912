<template>
  <div class="tabbar">
    <div class="tabbar-left">
      <img
        src="../../assets/img/logo_nav.png"
        style="width: 64px; height: 64px"
      />
    </div>

    <ul class="tabbar-right">
      <li
        @click="turnPage('index')"
        @mouseenter="lineEn('index')"
        @mouseleave="lineOut()"
      >
        <span ref="index">首页</span>
      </li>
      <!-- <li
        @click="turnPage('programme')"
        @mouseenter="lineEn('programme')"
        @mouseleave="lineOut()"
      >
        <span ref="programme">解决方案</span>
      </li> -->
      <li
        @click="turnPage('case')"
        @mouseenter="lineEn('case')"
        @mouseleave="lineOut()"
      >
        <span ref="case">成功案例</span>
      </li>
      <li
        @click="turnPage('about')"
        @mouseenter="lineEn('about')"
        @mouseleave="lineOut()"
      >
        <span ref="about">关于我们</span>
      </li>
      <li ref="last" :style="`width:${width}px;left:${left}px`"></li>
    </ul>
  </div>
</template>

<script>
export default {
  created() {},
  mounted() {
    this.page = this.$route.path.slice(1);
    this.width = this.$refs[this.page].clientWidth;
    this.left = this.$refs[this.page].offsetLeft;
    // console.log(this.page);
  },
  data() {
    return {
      left: 34,
      width: 32,
      page: "index",
    };
  },
  methods: {
    turnPage(page) {
      this.page = page;
      this.$router.push({ path: `/${page}` });
    },
    lineEn(element) {
      this.width = this.$refs[element].clientWidth;
      this.left = this.$refs[element].offsetLeft;
    },
    lineOut() {
      this.width = this.$refs[this.page].clientWidth;
      this.left = this.$refs[this.page].offsetLeft;
    },
  },
  watch: {
    page(newVal, oldVal) {
      this.width = this.$refs[newVal].clientWidth;
      this.left = this.$refs[newVal].offsetLeft;
      // console.log(newVal,'----',oldVal)
    },
  },
};
</script>

<style lang="scss" scoped>
.tabbar {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #fff;
  .tabbar-left {
    width: 64px;
    height: 64px;
    // border: 1px solid #fff;
    padding-top: 6px;
    overflow: hidden;
  }
  .tabbar-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 1100px;
    height: 70px;
    // border: 1px solid #fff;
    position: relative;

    li {
      cursor: pointer;
      display: inline-block;
      width: 100px;
      text-align: center;
      line-height: 70px;
      // margin-right: 40px;
      transition: all 0.5s;
      // border: 1px solid springgreen;
      span{
        display: inline-block;
        // border: 1px solid olive;
      }
    }
    li:last-child {
      position: absolute;
      top: 50px;
      left: 0;
      height: 3px;
      background-color: #fff;
      width: 0px;
    }
  }
}
// .line {
//   border-bottom: 3px solid #fff;
// }
</style>